.header {
    display: none;
    @media (max-width: 1200px) {
        display: block;
    }
    .logo img {
        max-width: 120px;
    }
}

.header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    position: absolute;
    width: 100%;
    height: 70px;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 9;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.leftpart {
    @media (max-width: 1200px) {
        transition: 0.5s;
        transform: translateX(-100%);
        &.active {
            transform: translateX(0);
        }
    }
}
.my_trigger .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.my_trigger .hamburger-box {
    width: 30px;
    height: 18px;
    display: inline-block;
    position: relative;
    top: 4px;
}
.my_trigger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.my_trigger .hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: #333333;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.my_trigger .hamburger-inner::before,
.my_trigger .hamburger-inner::after {
    content: "";
    display: block;
}
.my_trigger .hamburger-inner::before {
    top: -8px;
}
.my_trigger .hamburger-inner::after {
    bottom: -10px;
}
.my_trigger .hamburger--collapse-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.my_trigger .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger {
    padding: 0px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.my_trigger .hamburger .hamburger-inner::before,
.my_trigger .hamburger .hamburger-inner::after,
.my_trigger .hamburger .hamburger-inner {
    background-color: #000;
    width: 26px;
    transition: all 0.3s ease;
}
